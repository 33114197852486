export const ACCOUNT = 'ACCOUNT';
export const CACHED_ACCOUNT = 'CACHED_ACCOUNT';

export const ACTIVE_CHARGE = 'ACTIVE_CHARGE';

export const APPS = 'APPS';
export const APPS_SEGUNO_USAGE = 'APPS_SEGUNO_USAGE';

export const BILLING_USAGE_SUMMARY = 'BILLING_USAGE_SUMMARY';

export const BURST_CATEGORIES = 'BURST_CATEGORIES';
export const BURST_CATEGORY_PHOTOS = 'BURST_CATEGORY_PHOTOS';
export const BURST_SEARCH = 'BURST_SEARCH';

export const POPUPS = 'POPUPS';

export const POPUP_ENGAGEMENTS = 'POPUP_ENGAGEMENTS';

export const SHOPIFY_LINKS = 'SHOPIFY_LINKS';
export const SHOPIFY_LINK_PARSE = 'SHOPIFY_LINK_PARSE';

export const TEMPLATES = 'TEMPLATES';
export const TEMPLATE = 'TEMPLATE';

export const UPLOADED_IMAGES = 'UPLOADED_IMAGES';
export const CONTENT_FILE_IMAGES = 'CONTENT_FILE_IMAGES';

export const VERIFY_INSTALLATION = 'VERIFY_INSTALLATION';

export const POSTSCRIPT_KEYWORDS = 'POSTSCRIPT_KEYWORDS';

export const PRODUCT_IMAGES = 'PRODUCT_IMAGES';
