import { isNotBlank, ScrollToTop, WebVitalsWidget } from '@segunosoftware/equinox';
import '@segunosoftware/equinox/dist/styles.css';
import * as Sentry from '@sentry/react';
import '@shopify/polaris/build/esm/styles.css';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import APIProvider from './APIProvider';
import App from './App';
import queryClient from './queryClient';

if (import.meta.env.VITE_SENTRY_ENVIRONMENT !== 'development') {
	Sentry.init({
		release: isNotBlank(__SEGUNO_APP_VERSION__) ? `popup-frontend@${__SEGUNO_APP_VERSION__}` : undefined,
		dsn: 'https://c42492b542bc436fa2c7f7ebd01a5a3d@o155661.ingest.sentry.io/5614885',
		integrations: [new Sentry.BrowserTracing()],
		tracesSampleRate: 1,
		environment: import.meta.env.VITE_SENTRY_ENVIRONMENT
	});
}
const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
	<StrictMode>
		<APIProvider baseUrl={apiEndpoint}>
			<QueryClientProvider client={queryClient}>
				<Router>
					<ScrollToTop>
						<App />
					</ScrollToTop>
					<WebVitalsWidget enabled={import.meta.env.VITE_WEB_VITALS_WIDGET_ENABLED === '1'} />
				</Router>
				<ReactQueryDevtools />
			</QueryClientProvider>
		</APIProvider>
	</StrictMode>
);
