import { LoadingScreen } from '@segunosoftware/equinox';
import { useEffect, useRef, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAccountFetch } from '../hooks/useAccount';

export type ProtectedRouteProps = {
	element: JSX.Element;
};

export default function ProtectedRoute({ element }: ProtectedRouteProps) {
	const [appBridgeLoaded, setAppBridgeLoaded] = useState(false);
	const appBridgeScriptInjected = useRef(false);

	useEffect(() => {
		if (!appBridgeScriptInjected.current) {
			appBridgeScriptInjected.current = true;
			const appBridgeScript = document.createElement('script');
			appBridgeScript.src = 'https://cdn.shopify.com/shopifycloud/app-bridge.js';
			appBridgeScript.async = false;
			appBridgeScript.onload = () => setAppBridgeLoaded(true);
			document.head.prepend(appBridgeScript);
		}
	}, []);

	if (appBridgeLoaded) {
		return <ProtectedRouteWithAppBridge element={element} />;
	}

	return <LoadingScreen />;
}

type ProtectedRouteWithAppBridgeProps = {
	element: JSX.Element;
};

function ProtectedRouteWithAppBridge({ element }: ProtectedRouteWithAppBridgeProps) {
	const { account } = useAccountFetch(true);
	const location = useLocation();

	if (!account) {
		return <LoadingScreen />;
	}

	const isSuspendedPath = location.pathname.indexOf('/suspended') === 0;
	if (account.suspended && !isSuspendedPath) {
		return <Navigate to="/suspended" />;
	} else if (!account.suspended && isSuspendedPath) {
		return <Navigate to="/" />;
	}

	return element;
}
